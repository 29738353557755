import { Fragment } from 'react';
import { useUnmount } from 'react-use';
import {
  Button,
  chakra,
  Divider,
  Flex,
  FlexProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { GeneralTrackingEvent, useAnalytics } from '@arena-labs/analytics';
import {
  ChevronRightIcon,
  formatRelativeTime,
  Icon,
  Markdown,
  WaitForQuery,
} from '@arena-labs/strive2-ui';
import { $RESOURCES, ResourceApiResponse } from '@strive/api';

import { useNetworkState } from '../../lib/network-state.machine';

export function ServiceStatusNotice() {
  const networkState = useNetworkState();
  const serviceStatus = $RESOURCES.useGetServiceStatus(
    { params: { env: process.env.NEXT_PUBLIC_ENVIRONMENT || 'production' } },
    { refetchInterval: 1000 * 30 },
  );

  const containerStyles = {
    gridArea: 'content-footer',
    bg: 'negative.300',
    color: 'white',
    p: '2',
    fontSize: 'sm',
    display: 'flex',
    alignItems: 'center',
    textStyle: 'copy_small',
    role: 'region',
    'aria-live': 'polite',
    'aria-label': 'Service Status',
  } satisfies FlexProps;

  const defaultNotice =
    networkState === 'offline' ? (
      <Flex {...containerStyles}>Network issues</Flex>
    ) : null;

  const analytics = useAnalytics();
  const disruptionDetails = useDisclosure({
    onOpen: () =>
      analytics.logEvent(GeneralTrackingEvent.ServiceDisruptionModalOpen),
    onClose: () =>
      analytics.logEvent(GeneralTrackingEvent.ServiceDisruptionModalClosed),
  });

  return (
    <WaitForQuery
      query={serviceStatus}
      loading={defaultNotice}
      error={defaultNotice}
    >
      {(result) =>
        result.status === 'ok' ? (
          defaultNotice
        ) : (
          <Flex {...containerStyles} gap="4">
            <Text textStyle="copy_small" noOfLines={1}>
              {result.issue?.title ?? 'Service Interuption'}
            </Text>
            <chakra.button
              ml="auto"
              textStyle="copy_extra_small"
              flexShrink="0"
              alignSelf="start"
              textDecoration="underline"
              onClick={disruptionDetails.onOpen}
              aria-label="More Information on service interruption"
            >
              More info <Icon as={ChevronRightIcon} size="2" ml="1" />
            </chakra.button>
            {result.issue && (
              <ServiceStatusModal
                isOpen={disruptionDetails.isOpen}
                onClose={disruptionDetails.onClose}
                issue={result.issue}
              />
            )}
          </Flex>
        )
      }
    </WaitForQuery>
  );
}

type ServiceDisruption = NonNullable<
  Extract<
    ResourceApiResponse<'getServiceStatus'>,
    { status: 'disrupted' }
  >['issue']
>;

type ServiceStatusModalProps = {
  isOpen: boolean;
  onClose: () => void;
  issue: ServiceDisruption;
};

function ServiceStatusModal({
  isOpen,
  onClose,
  issue,
}: ServiceStatusModalProps) {
  // If the disruption is resolved, make sure we reset the modal state
  useUnmount(() => onClose());

  return (
    <Modal isOpen={isOpen} isCentered scrollBehavior="inside" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalHeader textStyle={'copy'}>{issue.title}</ModalHeader>

        <ModalBody>
          <Flex direction="column" gap="4">
            {issue.comments.toReversed().map((comment) => (
              <Fragment key={comment.id}>
                <CommentBlock
                  time={comment.updated_at}
                  content={comment.body}
                />
                <Divider />
              </Fragment>
            ))}
            <CommentBlock time={issue.published} content={issue.description} />
          </Flex>
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="space-between" gap="4">
          <Button variant="primary" onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

type CommentBlockProps = {
  time: Date;
  content: string;
};

function CommentBlock({ time, content }: CommentBlockProps) {
  return (
    <Flex direction="column" gap="1">
      <Text textStyle="copy_extra_small" color="warning.200">
        {formatRelativeTime(time)}
      </Text>
      <Markdown textStyle="copy_small">{content}</Markdown>
    </Flex>
  );
}
