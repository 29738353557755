import { chakra, Flex, Text } from '@chakra-ui/react';

import { useHomepage } from '@arena-labs/strive2-coaching';
import { Icon, IconProps } from '@arena-labs/strive2-ui';

type FooterLinkProps = {
  icon: IconProps['as'];
  label: string;
  isCurrent: boolean;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLElement>;
  onNavigate: (page: string) => void;
};
export function FooterLink({
  icon,
  label,
  isCurrent,
  children,
  onNavigate,
}: FooterLinkProps) {
  const [homepage] = useHomepage();
  return (
    <chakra.a
      onClick={() => onNavigate(label)}
      aria-current={String(isCurrent) as 'true' | 'false'}
      px={homepage?.learning_state === 'launchpad' ? '15px' : '6px'}
      py="1"
      color={isCurrent ? 'neutral.white' : 'neutral.800'}
      alignItems="center"
      justifyContent="center"
      position="relative"
    >
      <Flex
        direction={'column'}
        gap={2}
        w={'58px'}
        h={'58px'}
        bg={isCurrent ? 'logo' : 'transparent'}
        rounded={'full'}
        transition={'all 0.3s ease-in-out'}
      >
        <Icon as={icon} size={6} mt={1.5} mb={'-2px'} />
        <Text
          lineHeight={'shorter'}
          textStyle={'copy_extra_small'}
          textAlign="center"
          color={
            isCurrent ? 'strive.appFooter.activeFg' : 'strive.appFooter.fg'
          }
        >
          {label}
        </Text>
        {children}
      </Flex>
    </chakra.a>
  );
}
