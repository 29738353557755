import { useState } from 'react';
import { useRouter } from 'next/router';
import { BoxProps } from '@chakra-ui/react';

import { useUnreadCount } from '@arena-labs/chat';
import {
  ChatIcon,
  HomeIcon,
  ProgressIcon,
  ToolkitIcon,
} from '@arena-labs/strive2-ui';
import { useUserProfile } from '@strive/api';
import { IfWearable } from '@strive/wearable';

import { useStriveChatContext } from '../chat/chat-provider';
import { useFeatureUpdateIndicator } from '../version-update/feature-update-indicator';
import { FooterLink } from './footer-link';
import { FooterNav } from './footer-nav';
import { FooterNotification } from './footer-notification';

export type AppFooterProps = {
  sticky?: boolean;
  tabs?: React.ReactNode;
} & BoxProps;

export function AppFooter({ sticky, tabs, ...props }: AppFooterProps) {
  const router = useRouter();
  const getInitialPage = () => {
    const path = router.pathname;

    if (path.startsWith('/practices')) {
      return 'Toolkit';
    }
    if (path.startsWith('/chat')) {
      return 'Chat';
    }
    if (path.startsWith('/progress')) {
      return 'Progress';
    }
    if (
      router.asPath === '/' ||
      router.asPath.startsWith('/user') ||
      router.asPath.startsWith('/legal') ||
      router.asPath.startsWith('/coaching/ambassadors')
    ) {
      return 'Home';
    }
  };
  const [currentPage, setCurrentPage] = useState(getInitialPage());

  const { data: user } = useUserProfile();
  const { channel } = useStriveChatContext();
  const unreadCount = useUnreadCount(channel);

  const enableChat = Boolean(user?.profile.team_coach_stream_username);

  const newIndicator = {
    Home: useFeatureUpdateIndicator('Home'),
    Practice: useFeatureUpdateIndicator('Practice'),
    Chat: useFeatureUpdateIndicator('Chat'),
    Videos: useFeatureUpdateIndicator('Videos'),
    Data: useFeatureUpdateIndicator('Data'),
  };

  const handleNavigate = (page: string) => {
    setCurrentPage(page);
    const href = {
      Home: '/',
      Toolkit: '/practices',
      Chat: '/chat',
      Progress: '/progress',
    }[page];

    if (href) {
      router.push(href, undefined, { shallow: true });
    }
  };

  return (
    <FooterNav tabs={tabs} sticky={sticky} {...props}>
      <FooterLink
        label="Home"
        icon={HomeIcon}
        isCurrent={currentPage === 'Home'}
        onNavigate={handleNavigate}
      >
        <FooterNotification id="Home" isNew={newIndicator.Home} />
      </FooterLink>

      <FooterLink
        label="Toolkit"
        icon={ToolkitIcon}
        isCurrent={currentPage === 'Toolkit'}
        onNavigate={handleNavigate}
      >
        <FooterNotification id="Practice" isNew={newIndicator.Practice} />
      </FooterLink>

      {enableChat && (
        <FooterLink
          label="Chat"
          icon={ChatIcon}
          isCurrent={currentPage === 'Chat'}
          onNavigate={handleNavigate}
        >
          <FooterNotification
            id="chat"
            count={unreadCount}
            isNew={newIndicator.Chat}
          />
        </FooterLink>
      )}
      <IfWearable>
        <FooterLink
          label="Progress"
          icon={ProgressIcon}
          isCurrent={currentPage === 'Progress'}
          onNavigate={handleNavigate}
        >
          <FooterNotification id="biometrics" isNew={newIndicator.Data} />
        </FooterLink>
      </IfWearable>
    </FooterNav>
  );
}
